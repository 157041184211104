import React, { useState, useRef } from "react";
import { ReactComponent as CancelIcon } from "../../assets/svg/cancel-icon.svg";
import "./customise-preference.scss";

const CustomisePreferenceModal = ({
  closeHandler,
  acceptHandler,
  closePreferenceHandler
}) => {
  const mainRef = useRef();
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [cookiePolicy, setCookiePolicy] = useState(false);

  const declineHandler = () => {
    const payload = { privacyPolicy: false, cookiePolicy: false };
    acceptHandler(payload);
    closeHandler();
  };

  const savePreferenceHandler = () => {
    const payload = { privacyPolicy, cookiePolicy };
    acceptHandler(payload);
    closeHandler();
  };

  const acceptAllHandler = () => {
    const payload = { privacyPolicy: true, cookiePolicy: true };
    acceptHandler(payload);
    closeHandler();
  };

  const closeHandlerHandler = (event) => {
    if (event.target === mainRef.current) closePreferenceHandler();
  };

  return (
    <section
      className="main-section"
      ref={mainRef}
      onClick={closeHandlerHandler}
    >
      <section>
        {/* header */}
        <div className="header">
          <h3>Customise Preference</h3>
          <CancelIcon
            onClick={closePreferenceHandler}
            className="cancel-icon"
          />
        </div>

        {/* body */}
        <div className="body">
          {/* privacy policy */}
          <div className="mt-4">
            <h4 className="privacy-policy">Privacy Policy</h4>
            <p>
              At Oval Finance, your privacy is our top priority. We handle your
              personal information with the utmost care, using it solely to
              enhance your experience on our platform. We want to assure you
              that safeguarding your data privacy is paramount to us.
              <br />
              <br />
              By continuing to use our platform, you implicitly consent to the
              processing of your personal data by Oval Finance, its
              subsidiaries, and trusted partners. We strictly adhere to
              guidelines and other relevant data privacy regulations, ensuring
              your data is handled responsibly and in full compliance with the
              law.
            </p>
          </div>

          {/* cookie policy */}
          <div className="mt-9">
            <h4 className="mb-4 font-bold">Cookie Policy</h4>
            <div>
              Oval’s Cookie Policy: When you visit our website, our website
              sends a cookie to your device. We use cookies to improve your
              experience while on our website.
              <br />
              <br />
              <strong>Use of Geolocation API</strong>
              <p>
                In addition to cookies, we may use the Geolocation API to
                determine your location to enhance your experience on our
                platform. This allows us to comply with regulatory requirements.
              </p>
              <br />
              <ul className="list-disc px-5 my-4">
                <li>
                  <strong>Session Cookies:</strong> These cookies expire when
                  you leave the site or close your browser. They store temporary
                  information to improve site navigation.
                </li>
                <li>
                  <strong>Persistent Cookies:</strong> These cookies remain
                  stored on your device until manually deleted. They help
                  remember preferences for a personalized experience.
                </li>
              </ul>
              <p>
                If you follow a link from our website to another website, please
                be aware that the owner of the other website will have their own
                privacy and cookie policies.
              </p>
              <p>
                Cookie Preferences: Various browsers have different methods of
                managing cookies (including disabling options). However, some
                cookies are essential, and disabling them may result in reduced
                functionality.
              </p>
              <p>
                For more information on managing cookies, visit:
                <a
                  href="https://www.aboutcookies.org"
                  className="text-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  aboutcookies.org
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="footer">
          <div className="check-container">
            <span className="text-[#5D5E68] font-semibold">Privacy Policy</span>
            <label htmlFor="myCheckbox1" className="checkbox-container">
              <input
                id="myCheckbox1"
                type="checkbox"
                checked={privacyPolicy}
                onChange={() => setPrivacyPolicy(!privacyPolicy)}
              />
              <div className="ball"></div>
            </label>
          </div>
          <div className="check-container">
            <span className="text-[#5D5E68] font-semibold">Cookie Policy</span>
            <label htmlFor="myCheckbox2" className="checkbox-container">
              <input
                id="myCheckbox2"
                type="checkbox"
                checked={cookiePolicy}
                onChange={() => setCookiePolicy(!cookiePolicy)}
              />
              <div className="ball"></div>
            </label>
          </div>

          {/* buttons */}
          <div className="buttons">
            <div
              id="secondary-btn"
              className="secondaryBtn decline-all"
              onClick={declineHandler}
            >
              Decline All
            </div>
            <div
              id="secondary-btn"
              className="secondaryBtn"
              onClick={savePreferenceHandler}
            >
              Save Preference
            </div>
            <div
              id="primary-btn"
              className="primaryBtn"
              onClick={acceptAllHandler}
            >
              Accept All
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default CustomisePreferenceModal;
