import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as LinkedIn } from "../../assets/svg/socials/linked-in.svg";
import { ReactComponent as Medium } from "../../assets/svg/socials/medium.svg";
// import { ReactComponent as Twitter } from "../../assets/svg/socials/twitter.svg";
import { ReactComponent as Vect21 } from "../../assets/svg/footer-vector.svg";

import "./footer.scss";

const Footer = () => {
  return (
    <section className="footer">
      <Vect21 className="vect-21" />

      <div className="footer-wrapper desktop-max-width">
        <div className="f-top">
          <div className="f-info">
            <div className="f-logo">
              <Logo />
            </div>

            <div className="f-i-desc">
              <a className="click" href="mailto:hello@ovalfi.com">
                hello@ovalfi.com
              </a>
            </div>

            <div className="f-i-title">USA</div>

            <div className="f-i-desc">
              Oval Labs Inc.
              <br />8 The Green, Ste A, Dover, DE 19901.
            </div>

            <div className="f-i-title">Bermuda</div>

            <div className="f-i-desc">
              Oval Finance ISA.
              <br />
              Park Place 55 Par La Ville Road,
              <br />
              Third Floor Hamilton, Bermuda, HM11.
            </div>
          </div>

          <div className="f-group">
            <div className="f-g-title">COMPANY</div>

            <div className="f-g-item">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>

            <div className="f-g-item">
              <Link to="/terms-of-service">Terms of Service</Link>
            </div>

            <div className="f-g-item">
              <a
                href="https://medium.com/@ovalfi"
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
            </div>

            <div className="f-g-item">
              <Link to="/contact-us">Contact Us</Link>
            </div>
          </div>

          <div className="f-group">
            <div className="f-g-title">SOCIALS</div>

            <div className="s-items">
              {/* <div>
                <a
                  href="https://twitter.com/ovaldefi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twitter />
                </a>
              </div> */}

              <div>
                <a
                  href="https://medium.com/@ovalfi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Medium />
                </a>
              </div>

              <div>
                <a
                  href="https://www.linkedin.com/company/ovalfi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedIn />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="divider" />

        <div className="f-bottom">
          © {new Date().getFullYear()} Oval Labs, Inc.
        </div>
      </div>
    </section>
  );
};

export default Footer;
