import React, { useEffect } from "react";
import Banner from "./Banner/Banner";
// import Features from "./Features/Features";
// import Core from "./Core/Core";
import Footer from "../../components/Footer/Footer";
import "./landing.scss";

const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="landing-page-container">
      <Banner />
      {/* <Features />
      <Core /> */}
      <Footer />
    </div>
  );
};

export default Landing;
