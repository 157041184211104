import React, { useEffect } from "react";
import {
  TermsHeaderComponent,
  TermsTextBodyComponent,
  TermsListBodyComponent,
  TermsContainer
} from "../../components/TermsComponent/TermsComponent";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="terms-page">
      <div className="terms-wrapper desktop-max-width-smaller">
        <div className="terms-intro">
          <p className="extra-21">OVAL FINANCE TERMS OF USE</p>
          <p>Effective Date: February 7, 2022</p>
        </div>
        <TermsTextBodyComponent>
          <p>
            PLEASE READ THESE TERMS OF USE AGREEMENT (THE{" "}
            <span className="bold-21">“TERMS OF USE”</span>) CAREFULLY AS IT IS
            A BINDING AGREEMENT BETWEEN YOU AND OVAL LABS, INC. (“OVAL FINANCE,”
            <span className="bold-21">“WE,”</span> OR{" "}
            <span className="bold-21">“US”</span>) CONCERNING YOUR ACCESS TO AND
            USE OF THE OVAL FINANCE WEBSITE (
            <a href="https://ovalfi.com">https://ovalfi.com</a>) (THE{" "}
            <span className="bold-21">“WEBSITE”</span>) AND DECENTRALIZED
            FINANCE (<span className="bold-21">“DEFI”</span>) WEB-HOSTED
            PLATFORM AND DASHBOARD (THE{" "}
            <span className="bold-21">“PLATFORM”</span>), AS WELL AS ANY OTHER
            WEBSITE, COMMUNITY, BLOG, MEDIA FORM, MEDIA CHANNEL, OR MOBILE
            WEBSITE RELATED, LINKED, OR OTHERWISE CONNECTED THERETO, INCLUDING
            ANY UPDATES THEREOF (EACH A{" "}
            <span className="bold-21">“SERVICE”</span> AND COLLECTIVELY, THE{" "}
            <span className="bold-21">“SERVICES”</span>).
          </p>
          <br />
          <br />
          <p>
            BY CONNECTING ANY BANK ACCOUNT OR CREDIT CARD, AND/OR BROWSING,
            ACCESSING OR USING ANY CONTENT, INFORMATION, SERVICES, FEATURES OR
            RESOURCES AVAILABLE OR ENABLED VIA THE WEBSITE OR SERVICES, YOU
            REPRESENT THAT
          </p>
          <TermsListBodyComponent>
            <li>
              YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE TERMS OF
              USE,{" "}
            </li>
            <li>
              YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH OVAL FINANCE,
              AND{" "}
            </li>
            <li>YOU HAVE THE AUTHORITY TO ENTER INTO THE TERMS OF USE.</li>
          </TermsListBodyComponent>
          <br />
          <p>
            THE TERM
            <span className="bold-21"> “YOU”</span> REFERS TO THE INDIVIDUAL OR
            LEGAL ENTITY, AS APPLICABLE, THAT IS USING OR ACCESSING THE SERVICES
            OR THAT IS IDENTIFIED AS THE USER WHEN YOU REGISTERED ON THE
            WEBSITE.
          </p>

          <br />
          <p>
            OUR SERVICES ALSO USE A SERVICE THAT ALLOWS YOU TO ACCESS A
            CUSTODIAL ACCOUNT (THE{" "}
            <span className="bold-21">“CUSTODIAL ACCOUNT”</span> ), WHICH IS
            OPERATED AND MANAGED BY PRIME TRUST, LLC (
            <span className="bold-21">“PRIME TRUST”</span>). THE CUSTODIAL
            ACCOUNT PROVIDES YOU THE ABILITY TO EXCHANGE FIAT FOR DIGITAL
            CURRENCY (THE
            <span className="bold-21"> “EXCHANGE SERVICES”</span>) WITH THIRD
            PARTIES. OUR SERVICES DOES NOT INCLUDE ANY EXCHANGE SERVICES. THE
            EXCHANGE SERVICES ARE GOVERNED BY THE TERMS OF SERVICE OF PRIME
            TRUST, WHICH YOU AGREE TO BE BOUND IN ORDER TO ACCESS THE EXCHANGE
            SERVICES.{" "}
          </p>
          <br />
          <p className="bold-21">
            IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF USE OR THE TERMS OF
            USE OF PRIME TRUST, YOU MAY NOT ACCESS OR USE THIS WEBSITE OR THE
            SERVICES.
          </p>
          <br />
          <p className="bold-21">
            You should be aware that the risk of loss in trading or holding
            digital currencies can be substantial. As with any asset, the value
            of digital currencies can go up or down and there can be a
            substantial risk that you lose money buying, selling, holding, or
            investing in digital currencies. Any information provided on the
            Website or the Platform shall remain on a purely informative basis
            and shall not, in any case whatsoever, constitute any financial,
            securities, or investment advice. Your access or use of the Website
            and Platform shall be at your own risk and peril.
          </p>
          <br />
          <p>
            Your use of, and participation in, certain Services may be subject
            to additional terms, including but not limited to the{" "}
            <a href="https://www.primetrust.com/tos">
              Prime Trust Terms of Service
            </a>{" "}
            (<span className="bold-21">“Supplemental Terms”</span> ) and such
            Supplemental Terms will either be listed in the Terms of Use or will
            be presented to you for your acceptance when you sign up to use the
            supplemental Service. If the Terms of Use are inconsistent with the
            Supplemental Terms, the Supplemental Terms shall control with
            respect to such Service. The Terms of Use and any applicable
            Supplemental Terms are referred to herein as the{" "}
            <span className="bold-21">“Agreement.”</span>
          </p>
          <br />
          <p>
            The Services primarily consist, without limitation, of the Website
            and Platform, which allow you to access a Custodial Account, in
            order to track and monitor a digital currency portfolio, including
            assets, debts, liquidity, pools, staking, claimable rewards and
            yield farming activities, as well as to exchange, invest and
            re-balance between supported DeFi platforms. The Platform also
            allows you to transfer fiat currency in the Custodial Accounts,
            which Oval Finance will allow you to convert the fiat currency into
            digital currency. Afterwards, Oval Finance will spread the digital
            currency across supported DeFi applications (each, an{" "}
            <span className="bold-21">“Ecosystem Partner”</span> ) and uses DeFi
            shortcut smart contracts that bundle cross-protocol actions to help
            you access unique investment opportunities in less time and generate
            high interest yields.
          </p>
          <br />
          <p>
            OVAL FINANCE IS A NON-CUSTODIAL PLATFORM AND DOES NOT HAVE ACCESS TO
            YOUR PASSWORD AND CANNOT INITIATE A TRANSFER OF YOUR DIGITAL
            CURRENCIES OR OTHERWISE ACCESS YOUR DIGITAL CURRENCIES WITHOUT YOUR
            CONSENT. OVAL FINANCE IS NOT A BROKER OR INTERMEDIARY AND IS IN NO
            WAY YOUR AGENT, ADVISOR, OR CUSTODIAN, AND WE DO NOT HAVE A
            FIDUCIARY RELATIONSHIP OR OBLIGATION TO YOU REGARDING ANY DECISIONS
            OR ACTIVITIES THAT YOU EFFECT WHEN USING YOUR CUSTODIAL ACCOUNT OR
            OUR SERVICES. WE ARE NOT RESPONSIBLE FOR ANY ACTIVITIES THAT YOU
            ENGAGE IN WHEN USING YOUR CUSTODIAL ACCOUNT, AND YOU SHOULD
            UNDERSTAND THE RISKS ASSOCIATED WITH ETHEREUM OR ANY DIGITAL
            CURRENCIES. UNLESS EXPLICITLY PROVIDED IN WRITING, WE DO NOT HOST OR
            MAINTAIN ECOSYSTEM PARTNERS ACCESSIBLE ON OUR SERVICES AND DO NOT
            PARTICIPATE IN ANY TRANSACTIONS ON SUCH ECOSYSTEM PARTNERS,
            RECOMMEND, ENDORSE, OR OTHERWISE TAKE A POSITION ON YOUR USE OF
            THEIR SERVICES.
          </p>
          <br />
          <p>
            PLEASE NOTE THAT THE AGREEMENT IS SUBJECT TO CHANGE BY OVAL FINANCE
            IN ITS SOLE DISCRETION AT ANY TIME. When changes are made, Oval
            Finance will make a new copy of the Terms of Use available within
            the Services and any new Supplemental Terms will be made available
            from within, or through, the affected Service. We will also update
            the “Last Updated” date at the top of the Terms of Use. Any changes
            to the Agreement will be effective immediately for new users of the
            Website and/or Services and will be effective thirty (30) days after
            posting notice of such changes on the Website and/or Services for
            existing users of the Services. Oval Finance may require you to
            provide consent to the updated Agreement in a specified manner
            before further use of the Website and/or the Services is permitted.
            If you do not agree to any change(s) after receiving a notice of
            such change(s), you shall stop using the Website and/or the
            Services. Otherwise, your continued use of the Website and/or
            Services constitutes your acceptance of such change(s). PLEASE
            REGULARLY CHECK THE WEBSITE TO VIEW THE THEN-CURRENT TERMS.
          </p>
          <br />
          <p>
            For the purposes of these Terms of Use, Services shall include a
            cloud-based software as a service (SAAS) platform for professional
            investors, financial institutions, and fintech companies to manage,
            transact, transfer and track digital assets and digital pools. You
            further understand that the Services are intended for market exempt
            dealers, institutional and professional investors, financial
            institutions, regulated entities, individuals with securities or
            investor protection regulator government agencies, self-regulatory
            organizations, and fintech companies.
          </p>
          <br />
        </TermsTextBodyComponent>

        <ul className="terms_list_body_contaner">
          <TermsContainer>
            <TermsHeaderComponent>
              USE OF THE SERVICES AND OVAL FINANCE PROPERTIES.
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                The Services and the information and content available in the
                Services (collectively, the{" "}
                <span className="bold-21">“Oval Finance Properties”</span> ) are
                protected by copyright laws throughout the world. Subject to the
                Agreement, Oval Finance grants you a limited license to use Oval
                Finance Properties and reproduce portions of Oval Finance
                Properties for the sole purpose of using the Services for your
                personal or internal business purposes. Unless otherwise
                specified by Oval Finance in a separate license, your right to
                use any and all Oval Finance Properties is subject to the
                Agreement.
              </p>
            </TermsTextBodyComponent>

            <TermsListBodyComponent>
              <li>
                <TermsHeaderComponent>Updates</TermsHeaderComponent>
                <p>
                  You understand that Oval Finance Properties are evolving. You
                  acknowledge and agree that Oval Finance may update Oval
                  Finance Properties with or without notifying you. You may need
                  to update third-party software from time to time in order to
                  use Oval Finance Properties.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Certain Restrictions
                </TermsHeaderComponent>
                <p>
                  The rights granted to you in the Agreement are subject to the
                  following restrictions: (a) you shall not license, sell, rent,
                  lease, transfer, assign, reproduce, distribute, host or
                  otherwise commercially exploit Oval Finance Properties or any
                  portion of Oval Finance Properties, except as expressly stated
                  herein; (b) you shall not frame or utilize framing techniques
                  to enclose any trademark, logo, or other Oval Finance
                  Properties (including images, text, page layout or form) of
                  Oval Finance; (c) you shall not use any metatags or other
                  “hidden text” using Oval Finance’s name or trademarks; (d) you
                  shall not modify, translate, adapt, merge, make derivative
                  works of, disassemble, decompile, reverse compile or reverse
                  engineer any part of Oval Finance Properties except to the
                  extent the foregoing restrictions are expressly prohibited by
                  applicable law; (e) you shall not use any manual or automated
                  software, devices or other processes (including but not
                  limited to spiders, robots, scrapers, crawlers, avatars, data
                  mining tools or the like) to “scrape” or download data from
                  any web pages contained in the Services (except that we grant
                  the operators of public search engines revocable permission to
                  use spiders to copy materials from the Website for the sole
                  purpose of and solely to the extent necessary for creating
                  publicly available searchable indices of the materials, but
                  not caches or archives of such materials); (f) except as
                  expressly stated herein, no part of Oval Finance Properties
                  may be copied, reproduced, distributed, republished,
                  downloaded, displayed, posted or transmitted in any form or by
                  any means; and (h) you shall not remove or destroy any
                  copyright notices or other proprietary markings contained on
                  or in Oval Finance Properties. Any future release, update or
                  other addition to Oval Finance Properties shall be subject to
                  the Agreement. Oval Finance, its suppliers and service
                  providers reserve all rights not granted in the Agreement. Any
                  unauthorized use of any Oval Finance Property terminates the
                  licenses granted by Oval Finance pursuant to the Agreement.
                </p>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>REGISTRATION</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                <TermsHeaderComponent>
                  Connecting to the Custodial Account
                </TermsHeaderComponent>
                <p>
                  In order to use the Platform and access certain features of
                  Oval Finance Properties, you must register and access a
                  Custodial Account.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Registration</TermsHeaderComponent>
                <p>
                  In order to access certain features of Oval Finance
                  Properties, you may be required to become a registered user.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Connected Content</TermsHeaderComponent>
                <p>
                  You hereby authorize Oval Finance to receive and/or access any
                  and all information from your Custodial Account necessary for
                  Oval Finance to provide the functionality of the Platform and
                  Services. You represent that you are entitled to grant Oval
                  Finance access to your Custodial Account (including, but not
                  limited to, for use for the purposes described herein) without
                  breach by you or any of the terms and conditions that govern
                  your use of the Custodial Account. By granting Oval Finance
                  access to your Custodial Account, you understand that Oval
                  Finance may access and make available and store (if
                  applicable) any information, data, text, software, music,
                  sound, photographs, graphics, video, messages, tags and/or
                  other materials accessible through Oval Finance Properties (
                  <span className="bold-21">“Content”</span>) or personal
                  financial data that you have provided to and stored in your
                  Custodial Account, as applicable (collectively, the
                  <span className="bold-21"> “Connected Content”</span>), so
                  that it is available on and through the Oval Finance
                  Properties via your Custodial Account. Please note that if a
                  Custodial Account or associated service becomes unavailable,
                  or Oval Finance’s access to such Custodial Account is
                  terminated by the third-party service provider, then Connected
                  Content will no longer be available on and through Oval
                  Finance Properties, and the functionality of the Platform and
                  Services may cease. Unless otherwise specified in the
                  Agreement, all Connected Content shall be considered to be
                  Your Content (as defined in Section 3 (Types of Content)) for
                  all purposes of the Agreement. PLEASE NOTE THAT YOUR
                  RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED
                  WITH YOUR CUSTODIAL ACCOUNT SUCH AS PRIME TRUST IS GOVERNED
                  SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE
                  PROVIDERS, AND OVAL FINANCE DISCLAIMS ANY LIABILITY FOR
                  PERSONALLY IDENTIFIABLE INFORMATION THAT MAY BE PROVIDED TO IT
                  BY SUCH THIRD-PARTY SERVICE PROVIDERS IN VIOLATION OF THE
                  PRIVACY SETTINGS THAT YOU HAVE SET IN SUCH CUSTODIAL ACCOUNTS.
                  Oval Finance makes no effort to review any Connected Content
                  for any purpose, including but not limited to, for accuracy,
                  legality or noninfringement, and Oval Finance is not
                  responsible for any Connected Content.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Representations</TermsHeaderComponent>
                <p>
                  You represent that you are (i) at least eighteen (18) years
                  old; (ii) of legal age to form a binding contract; and (iii)
                  not a person barred from using Oval Finance Properties under
                  the laws of the State of Texas and applicable federal laws of
                  the United States of America, your place of residence or any
                  other applicable jurisdiction. You are responsible for all
                  activities that occur during your use of the Services. You
                  agree that you shall monitor your use of the Services to
                  restrict use by minors, and you will accept full
                  responsibility for any unauthorized use of Oval Finance
                  Properties by minors.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Necessary Equipment and Software
                </TermsHeaderComponent>
                <p>
                  You must provide all equipment and software necessary to
                  connect to Oval Finance Properties, including but not limited
                  a mobile device that is suitable to connect with and use Oval
                  Finance Properties, in cases where the Services offer a mobile
                  component. You are solely responsible for any fees, including
                  Internet connection or mobile fees, that you incur when
                  accessing Oval Finance Properties. You will be responsible for
                  keeping your hardware devices, including your mobile device,
                  secure and for any activity associated with such devices and
                  your Custodial Account when using our Services. Oval Finance
                  will not be responsible if someone else accesses your devices
                  and authorizes a transaction upon receipt of a valid transfer
                  initiated from the Services.
                </p>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>
              RESPONSIBILITY FOR CONTENT
            </TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                <span className="bold-21">Types of Content.</span> You
                acknowledge that all Content, including Oval Finance Properties,
                is the sole responsibility of the party from whom such Content
                originated. This means that you, and not Oval Finance, are
                entirely responsible for all Content that you upload, post,
                e-mail, transmit or otherwise make available (
                <span className="bold-21">“Make Available”</span>) through Oval
                Finance Properties (
                <span className="bold-21">“Your Content”</span>), and that you,
                as a registered user, and other registered users of Oval Finance
                Properties, and not Oval Finance, are similarly responsible for
                all Content that you and they Make Available through Oval
                Finance Properties (
                <span className="bold-21">“User Content”</span>).
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>OWNERSHIP</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                <TermsHeaderComponent>
                  Oval Finance Properties
                </TermsHeaderComponent>
                <p>
                  Except with respect to any Open Source Software, your
                  Custodial Account, Your Content, Connected Content, and User
                  Content, you agree that Oval Finance and its suppliers own all
                  rights, title and interest in Oval Finance Properties
                  (including but not limited to, the Website, Platform, any
                  computer code, themes, objects, characters, character names,
                  stories, dialogue, concepts, artwork, animations, sounds,
                  musical compositions, audiovisual effects, methods of
                  operation, moral rights, documentation, and software). You
                  will not remove, alter or obscure any copyright, trademark,
                  service mark or other proprietary rights notices incorporated
                  in or accompanying any Oval Finance Properties.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Open Source Software
                </TermsHeaderComponent>
                <p>
                  You acknowledge that the Services may use, incorporate or link
                  to certain open-source components (
                  <span className="bold-21">“Open Source Software”</span>) and
                  that your use of the Services is subject to, and you will
                  comply with any, applicable “open source” or “free software”
                  licenses. Some of the Open Source Software is owned by third
                  parties. Each item of Open Source Software is licensed under
                  the terms of the end-user license that accompanies such Open
                  Source Software. Nothing in this Agreement limits your rights
                  under, or grants you rights that supersede, the terms and
                  conditions of any applicable end-user license for the Open
                  Source Software. If required by any license for particular
                  Open Source Software, Oval Finance makes such Open Source
                  Software, and Oval Finance’s modifications to that Open Source
                  Software, available by written request at the notice address
                  specified below.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Trademarks</TermsHeaderComponent>
                <p>
                  “Oval Finance”, and all related graphics, logos, service marks
                  and trade names used on or in connection with any Oval Finance
                  Properties or in connection with the Services are the
                  trademarks of Oval Finance and may not be used without
                  permission in connection with your, or any third party,
                  products or services. Other trademarks, service marks and
                  trade names that may appear on or in Oval Finance Properties
                  are the property of their respective owners.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Your Content</TermsHeaderComponent>
                <p>
                  Oval Finance does not claim ownership of Your Content.
                  However, when you as a registered user post or publish Your
                  Content on or in Oval Finance Properties, you represent that
                  you own and/or have a royalty-free, perpetual, irrevocable,
                  worldwide non-exclusive right (including any moral rights) and
                  license to use, license, reproduce, modify, adapt, publish,
                  translate, create derivative works from, distribute, derive
                  revenue or other remuneration from, and communicate to the
                  public, perform and display Your Content (in whole or in part)
                  worldwide and/or to incorporate it in other works in any form,
                  media or technology now known or later developed, for the full
                  term of any worldwide intellectual property right that may
                  exist in Your Content.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Feedback</TermsHeaderComponent>
                <p>
                  You agree that submission of any ideas, suggestions,
                  documents, and/or proposals to Oval Finance through Discord or
                  otherwise through the Website or its suggestion, feedback,
                  feature request, blog, community, forum, or similar pages (
                  <span className="bold-21">“Feedback”</span>) is at your own
                  risk and that Oval Finance has no obligations (including
                  without limitation obligations of confidentiality) with
                  respect to such Feedback. You represent and warrant that you
                  have all rights necessary to submit the Feedback. You hereby
                  grant to Oval Finance a fully paid, royalty-free, perpetual,
                  irrevocable, worldwide, non-exclusive, and fully sublicensable
                  right and license to use, reproduce, perform, display,
                  distribute, adapt, modify, re-format, create derivative works
                  of, and otherwise commercially or non-commercially exploit in
                  any manner, any and all Feedback, and to sublicense the
                  foregoing rights, in connection with the operation and
                  maintenance of Oval Finance Properties and/or Oval Finance’s
                  business.
                </p>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>USER CONDUCT</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                As a condition of use, you agree not to use Oval Finance
                Properties for any purpose that is prohibited by this Agreement
                or by applicable law. You shall not (and shall not permit any
                third party) either to (a) take any action or (b) Make Available
                any Content on or through Oval Finance Properties that: (i)
                infringes any patent, trademark, trade secret, copyright, right
                of publicity or other right of any person or entity; (ii) is
                unlawful, threatening, abusive, harassing, defamatory, libelous,
                deceptive, fraudulent, invasive of another’s privacy, tortious,
                obscene, offensive, or profane; (iii) constitutes unauthorized
                or unsolicited advertising, junk or bulk e-mail; (iv) involves
                commercial activities and/or sales, such as contests,
                sweepstakes, barter, advertising, or pyramid schemes without
                Oval Finance’s prior written consent; (v) impersonates any
                person or entity, including any employee or representative of
                Oval Finance; (vi) interferes with or attempts to interfere with
                the proper functioning of Oval Finance Properties or uses Oval
                Finance Properties in any way not expressly permitted by this
                Agreement; (vii) jeopardizes the security of your Custodial
                Account, or anyone else’s (such as allowing someone else to log
                in to the Services as you); (viii) attempts, in any manner, to
                obtain the password, account, or other security information from
                any other user; (ix) attempts to access another user’s Custodial
                Account, or other security information on any third-party site
                or services that provide access to such user’s Custodial
                Account; or (x) attempts to engage in, or engages in, any
                potentially harmful acts that are directed against Oval Finance
                Properties, including but not limited to violating or attempting
                to violate any security features of Oval Finance Properties,
                using manual or automated software or other means to access,
                “scrape,” “crawl” or “spider” any pages contained in Oval
                Finance Properties, introducing viruses, worms, or similar
                harmful code into Oval Finance Properties, or interfering or
                attempting to interfere with use of Oval Finance Properties by
                any other user, host or network, including by means of
                overloading, “flooding,” “spamming,” “mail bombing,” or
                “crashing” Oval Finance Properties.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>INVESTIGATIONS</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Oval Finance may, but is not obligated to, monitor or review
                Oval Finance Properties and Content at any time. Without
                limiting the foregoing, Oval Finance shall have the right, in
                its sole discretion, to remove any of Your Content for any
                reason (or no reason), including if such Content violates the
                Agreement or any applicable law. Although Oval Finance does not
                generally monitor user activity occurring in connection with
                Oval Finance Properties or Content, if Oval Finance becomes
                aware of any possible violations by you of any provision of the
                Agreement, Oval Finance reserves the right to investigate such
                violations, and Oval Finance may, at its sole discretion,
                immediately terminate your license to use Oval Finance
                Properties, or change, alter or remove Your Content, in whole or
                in part, without prior notice to you.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>
              INTERACTIONS WITH OTHER USERS
            </TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                <TermsHeaderComponent>User Responsibility</TermsHeaderComponent>
                <p>
                  You are solely responsible for your interactions with other
                  registered users and any other parties with whom you interact;
                  provided, however, that Oval Finance reserves the right, but
                  has no obligation, to intercede in such disputes. You agree
                  that Oval Finance will not be responsible for any liability
                  incurred as the result of such interactions.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Content Provided by Other Users
                </TermsHeaderComponent>
                <p>
                  Oval Finance Properties may contain User Content provided by
                  other registered users. Oval Finance is not responsible for
                  and does not control User Content. Oval Finance has no
                  obligation to review or monitor, and does not approve, endorse
                  or make any representations or warranties with respect to,
                  User Content. You use all User Content and interact with other
                  registered users at your own risk.
                </p>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>INDEMNIFICATION</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                You agree to indemnify and hold Oval Finance, its parents,
                subsidiaries, affiliates, officers, employees, agents, partners,
                suppliers, and licensors (each, a{" "}
                <span className="bold-21">“Oval Finance Party”</span> and
                collectively, the{" "}
                <span className="bold-21">“Oval Finance Parties”</span>)
                harmless from any losses, costs, liabilities and expenses
                (including reasonable attorneys’ fees) relating to or arising
                out of any and all of the following: (a) Your Content; (b) your
                use of, or inability to use, any Oval Finance Property; (c) your
                violation of the Agreement; (d) your violation of any rights of
                another party, including any registered users; or (e) your
                violation of any applicable laws, rules or regulations. Oval
                Finance reserves the right, at its own cost, to assume the
                exclusive defense and control of any matter otherwise subject to
                indemnification by you, in which event you will fully cooperate
                with Oval Finance in asserting any available defenses. This
                provision does not require you to indemnify any of the Oval
                Finance Parties for any unconscionable commercial practice by
                such party or for such party’s fraud, deception, false promise,
                misrepresentation or concealment, or suppression or omission of
                any material fact in connection with the Website or any Services
                provided hereunder. You agree that the provisions in this
                section will survive any termination of your account, the
                Agreement and/or your access to Oval Finance Properties.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>ASSUMPTION OF RISK</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                In order to be successfully completed, any digital currency
                transaction created with or sent to your Custodial Account must
                be confirmed and recorded in a blockchain associated with the
                relevant digital currency. Oval Finance has no control over any
                blockchain and therefore cannot and does not ensure that any
                transaction details that you submit or receive via our Services
                will be confirmed on the relevant blockchain and does not have
                the ability to facilitate any cancellation or modification
                requests. In addition, certain Ecosystem Partners may involve
                complex financial transactions that entail a high degree of
                risk. You accept and acknowledge that you take full
                responsibility for all activities that occur under your
                Custodial Account and accept all risks of loss or any authorized
                or unauthorized access to your Custodial Account, to the maximum
                extent permitted by law. You further accept and acknowledge
                that:
              </p>
            </TermsTextBodyComponent>
            <TermsListBodyComponent>
              <li>
                <p className="bold-21">
                  You (a) have the knowledge, experience, understanding,
                  professional advice and information to make your own
                  evaluation of the merits, risks and applicable compliance
                  requirements under applicable laws of any use of your
                  Custodial Account; (b) know, understand and accept the risks
                  associated with your Custodial Account; and (c) accept the
                  risks associated with digital currency generally, and are
                  responsible for conducting your own independent analysis of
                  the risks specific to digital currency. You further agree that
                  Oval Finance will have no responsibility or liability for such
                  risks. You hereby irrevocably waive, release and discharge all
                  claims, whether known or unknown to you, against Oval Finance,
                  its affiliates and their respective shareholders, members,
                  directors, officers, employees, agents and representatives
                  related to any of the risks set forth herein.
                </p>
              </li>

              <li>
                <p>
                  The prices of blockchain assets are extremely volatile. Oval
                  Finance makes no warranties as to the markets in which digital
                  currencies are transferred, purchased, or traded.
                </p>
              </li>

              <li>
                <p>
                  You are solely responsible for determining what, if any, taxes
                  apply to your digital currency transactions. Oval Finance is
                  not responsible for determining the taxes that apply to
                  digital currency transactions.
                </p>
              </li>

              <li>
                <p>
                  Oval Finance does not store, send, or receive digital
                  currencies or funds. This is because digital currencies exist
                  only by virtue of the ownership record maintained on their
                  supporting blockchain. Any transfer of digital currencies
                  occurs within the supporting blockchain and not in the
                  Services. The transaction details you submit via the Services
                  may not be completed, or may be substantially delayed, by the
                  relevant blockchain used to process the transaction. Once
                  transaction details have been submitted to a blockchain, Oval
                  Finance cannot assist you to cancel or otherwise modify your
                  transaction or transaction details.{" "}
                  <span className="bold-21">
                    There are no warranties or guarantees that a transfer
                    initiated on the Services will successfully transfer title
                    or right in any digital currencies.
                  </span>
                </p>
              </li>

              <li>
                <p>
                  There are risks associated with using an Internet based
                  currency, including but not limited to, the risk of hardware,
                  software and Internet connections, the risk of malicious
                  software introduction, and the risk that third parties may
                  obtain unauthorized access to information stored within your
                  Custodial Account. You accept and acknowledge that Oval
                  Finance will not be responsible for any communication
                  failures, disruptions, errors, distortions or delays or losses
                  you may experience when using digital currencies, however
                  caused.
                </p>
              </li>

              <li>
                <p>
                  The regulatory regime governing blockchain technologies,
                  cryptocurrencies, and tokens are uncertain, and new
                  regulations or policies may materially adversely affect the
                  development of the Services and the utility of digital
                  currencies.
                </p>
              </li>

              <li>
                <p>
                  Oval Finance makes no guarantee as to the functionality of any
                  blockchain’s decentralized governance, which could, among
                  other things, lead to delays, conflicts of interest, or
                  operational decisions that are unfavorable to certain owners
                  of digital currencies. You acknowledge and accept that the
                  protocols governing the operation of a blockchain may be
                  subject to sudden changes in operating rules which may
                  materially alter the blockchain and affect the value and
                  function of digital currencies evidenced on that blockchain.
                </p>
              </li>

              <li>
                <p>
                  Oval Finance makes no guarantee as to the security of any
                  blockchain. Oval Finance is not liable for any hacks, double
                  spending, stolen digital currencies, or any other attacks on a
                  blockchain.
                </p>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>
              DISCLAIMER OF WARRANTIES AND CONDITIONS
            </TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                <p>
                  <span className="bold-21">As Is.</span> YOU EXPRESSLY
                  UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY
                  APPLICABLE LAW, YOUR USE OF OVAL FINANCE PROPERTIES IS AT YOUR
                  SOLE RISK, AND OVAL FINANCE PROPERTIES ARE PROVIDED ON AN “AS
                  IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS. OVAL FINANCE
                  PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS,
                  AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                  INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR
                  CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                  PURPOSE AND NON-INFRINGEMENT ARISING FROM USE OF THE OVAL
                  FINANCE PROPERTIES. OVAL FINANCE PARTIES MAKE NO WARRANTY,
                  REPRESENTATION OR CONDITION THAT: (1) OVAL FINANCE PROPERTIES
                  WILL MEET YOUR REQUIREMENTS; (2) YOUR USE OF OVAL FINANCE
                  PROPERTIES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
                  ERROR-FREE; (3) THE RESULTS THAT MAY BE OBTAINED FROM USE OF
                  OVAL FINANCE PROPERTIES WILL BE ACCURATE OR RELIABLE. ANY
                  CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH OVAL
                  FINANCE PROPERTIES IS ACCESSED AT YOUR OWN RISK, AND YOU SHALL
                  BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY,
                  INCLUDING, BUT NOT LIMITED TO, YOUR COMPUTER SYSTEM AND ANY
                  DEVICE YOU USE TO ACCESS OVAL FINANCE PROPERTIES, OR ANY OTHER
                  LOSS THAT RESULTS FROM ACCESSING SUCH CONTENT. THE SERVICES
                  MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND OTHER DISRUPTIONS.
                  OVAL FINANCE MAKES NO WARRANTY, REPRESENTATION OR CONDITION
                  WITH RESPECT TO SERVICES, INCLUDING BUT NOT LIMITED TO, THE
                  QUALITY, EFFECTIVENESS, REPUTATION AND OTHER CHARACTERISTICS
                  OF SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                  WRITTEN, OBTAINED FROM OVAL FINANCE OR THROUGH OVAL FINANCE
                  PROPERTIES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
                </p>
                <p className="bold-21">
                  OVAL FINANCE DOES NOT RECOMMEND, ENDORSE, ADVOCATE OR SPONSOR
                  ANY OF THE LIQUIDITY POOLS, DIGITAL CURRENCY PAIRS OR DIGITAL
                  CURRENCY TRANSACTIONS APPEARING ON OR MADE THROUGH THE OVAL
                  FINANCE PROPERTIES. OVAL FINANCE IS NOT AN AUTHORIZED
                  FINANCIAL ADVISOR OR REPRESENTATIVE AND DOES NOT GIVE TAX,
                  EMPLOYMENT, OR INVESTMENT ADVICE. ANY PRICE INFORMATION,
                  QUOTES, FORECASTS, RETURN ESTIMATES, OR INDICATIONS OF PAST
                  PERFORMANCE ARE FOR INFORMATION PURPOSES ONLY AND DO NOT
                  GUARANTEE FUTURE PERFORMANCE AND DO NOT CONSTITUTE AN OFFER TO
                  BUY OR SELL OR ANY SOLICITATION OF AN OFFER TO BUY OR SELL ANY
                  CURRENCIES, CURRENCY PAIRS, OR OTHER PROPERTY, NOR TO ENTER
                  INTO ANY DIGITAL CURRENCY TRANSACTION. YOU AGREE THAT THE OVAL
                  FINANCE PROPERTIES SHALL NOT SERVE AS THE PRIMARY BASIS FOR
                  ANY OF YOUR DECISIONS, AND OVAL FINANCE SHALL NOT BE OR BE
                  DEEMED TO BE YOUR FINANCIAL ADVISOR OR FIDUCIARY. OVAL FINANCE
                  STRONGLY ADVISES YOU TO OBTAIN ADVICE AND ASSISTANCE FROM A
                  SECURITIES, COMMODITIES, OR FINANCIAL SERVICES PROFESSIONAL,
                  AS APPROPRIATE, REGARDING THE EVALUATION OF ANY SPECIFIC
                  INDEX, REPORT, RESEARCH, FORECAST, OPINION, ADVICE, DATA OR
                  OTHER CONTENT.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  No Liability for Conduct of Third Parties.
                </TermsHeaderComponent>
                <p>
                  YOU ACKNOWLEDGE AND AGREE THAT OVAL FINANCE PARTIES ARE NOT
                  LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD OVAL FINANCE PARTIES
                  LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OPERATORS
                  OF EXTERNAL WEBSITES, AND THAT THE RISK OF INJURY FROM SUCH
                  THIRD PARTIES RESTS ENTIRELY WITH YOU. OVAL FINANCE MAKES NO
                  WARRANTY THAT THE GOODS OR SERVICES PROVIDED BY THIRD PARTIES
                  WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN
                  UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. OVAL FINANCE MAKES
                  NO WARRANTY REGARDING THE QUALITY OF ANY SUCH GOODS OR
                  SERVICES, OR THE ACCURACY, TIMELINESS, TRUTHFULNESS,
                  COMPLETENESS OR RELIABILITY OF ANY CONNECTED CONTENT OBTAINED
                  THROUGH OVAL FINANCE PROPERTIES.
                </p>
              </li>

              <li>
                <p>
                  Notwithstanding anything to the contrary in these Terms of
                  Use, Oval Finance shall be under no obligation to inquire into
                  and shall not be liable for any damages, other liabilities or
                  harm to any person or entity relating to (i) the ownership,
                  validity or genuineness of any digital currency; (ii) the
                  collectability, insurability, effectiveness, marketability or
                  suitability of any digital currency; or (iii) any losses,
                  delays, failures, errors, interruptions or loss of data
                  occurring directly or indirectly by reason of circumstances
                  beyond Oval Finance’s control, including without limitation
                  the failure of a blockchain, third-party services provider, or
                  Ecosystem Partner.
                </p>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>LIMITATION OF LIABILITY</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                <TermsHeaderComponent>
                  Disclaimer of Certain Damages
                </TermsHeaderComponent>
                <p>
                  YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PROVIDED
                  BY LAW, IN NO EVENT SHALL OVAL FINANCE PARTIES BE LIABLE FOR
                  ANY LOSS OF PROFITS, ETHER, STABLE COINS, OR OTHER VIRTUAL
                  CURRENCY, REVENUE OR DATA, INDIRECT, INCIDENTAL, SPECIAL, OR
                  CONSEQUENTIAL DAMAGES, OR DAMAGES OR COSTS DUE TO LOSS OF
                  PRODUCTION OR USE, BUSINESS INTERRUPTION, OR PROCUREMENT OF
                  SUBSTITUTE GOODS OR SERVICES, IN EACH CASE WHETHER OR NOT OVAL
                  FINANCE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
                  ARISING OUT OF OR IN CONNECTION WITH THE AGREEMENT OR ANY
                  COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF
                  OVAL FINANCE PROPERTIES, ON ANY THEORY OF LIABILITY, RESULTING
                  FROM: (a) THE USE OR INABILITY TO USE OVAL FINANCE PROPERTIES;
                  (b) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES
                  RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES
                  PURCHASED OR OBTAINED, OR MESSAGES RECEIVED FOR TRANSACTIONS
                  ENTERED INTO THROUGH OVAL FINANCE PROPERTIES; (c) UNAUTHORIZED
                  ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (d)
                  STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON OVAL FINANCE
                  PROPERTIES; OR (e) ANY OTHER MATTER RELATED TO OVAL FINANCE
                  PROPERTIES, WHETHER BASED ON WARRANTY, COPYRIGHT, CONTRACT,
                  TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER
                  LEGAL THEORY. THE FOREGOING LIMITATION OF LIABILITY SHALL NOT
                  APPLY TO LIABILITY OF AN OVAL FINANCE PARTY FOR (i) DEATH OR
                  PERSONAL INJURY CAUSED BY AN OVAL FINANCE PARTY’S NEGLIGENCE;
                  OR FOR (ii) ANY INJURY CAUSED BY AN OVAL FINANCE PARTY’S FRAUD
                  OR FRAUDULENT MISREPRESENTATION. OVAL FINANCE DOES NOT IN ANY
                  WAY SEEK TO EXCLUDE OR LIMIT LIABILITY FOR (i) DEATH OR
                  PERSONAL INJURY CAUSED BY OVAL FINANCE’S NEGLIGENCE; (ii)
                  FRAUD OR FRAUDULENT MISREPRESENTATION; OR (iii) ANY OTHER
                  LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED BY ENGLISH, EU
                  LAW, OR ANY APPLICABLE LAW.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Exclusion of Damages
                </TermsHeaderComponent>
                <p>
                  CERTAIN JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION
                  OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF
                  THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
                  YOU MIGHT HAVE ADDITIONAL RIGHTS.
                </p>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>TERM AND TERMINATION</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                <TermsHeaderComponent>Term</TermsHeaderComponent>
                <p>
                  The Agreement commences on the date when you accept these
                  Terms of Use (as described in the preamble above) and remain
                  in full force and effect while you use Oval Finance
                  Properties, unless terminated earlier in accordance with the
                  Agreement.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Termination of Services by Oval Finance
                </TermsHeaderComponent>
                <p>
                  Oval Finance is free to terminate (or suspend access to) your
                  use of the Services for any reason at our discretion,
                  including your breach of these Terms of Use. Oval Finance has
                  the sole right to decide whether you are in violation of any
                  of the restrictions set forth in these Terms of Use. Oval
                  Finance will not have any liability whatsoever to you for any
                  suspension or termination.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Termination of Services by You
                </TermsHeaderComponent>
                <p>
                  If you want to terminate the Services provided by Oval
                  Finance, you may do so by closing your account, if applicable,
                  by providing written notice to us, and by logging out of all
                  of the Services that you use at any time. You may continue to
                  access your Custodial Account directly or through other
                  services not hosted by us.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Survival</TermsHeaderComponent>
                <p>
                  All provisions of the Agreement which by their nature should
                  survive, shall survive termination of Services, including
                  without limitation, ownership provisions, warranty
                  disclaimers, and limitation of liability.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>No Subsequent Use</TermsHeaderComponent>
                <p>
                  If your ability to access Oval Finance Properties or any other
                  Oval Finance community is discontinued by Oval Finance due to
                  your violation of any portion of the Agreement or for conduct
                  otherwise inappropriate for the community, then you agree that
                  you shall not attempt to access Oval Finance Properties or any
                  Oval Finance community through use of a different member name
                  or otherwise. In the event that you violate the immediately
                  preceding sentence, Oval Finance reserves the right, in its
                  sole discretion, to immediately take any or all of the actions
                  set forth herein without any notice or warning to you.
                </p>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>INTERNATIONAL USERS</TermsHeaderComponent>
            <TermsTextBodyComponent>
              <p>
                Oval Finance Properties can be accessed from countries around
                the world and may contain references to Services and Content
                that are not available in your country. These references do not
                imply that Oval Finance intends to announce such Services or
                Content in your country. Oval Finance makes no representations
                that Oval Finance Properties are appropriate or available for
                use in other locations. Those who access or use Oval Finance
                Properties from other countries do so at their own volition and
                are responsible for compliance with local law.
              </p>
            </TermsTextBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>THIRD-PARTY SERVICES</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                <TermsHeaderComponent>
                  Third-Party Websites, Applications and Ads
                </TermsHeaderComponent>
                <p>
                  Oval Finance Properties may contain links to third party
                  websites, applications, and advertisements for third parties,
                  including but not limited to Prime Trust (
                  <span className="bold-21">“Third-Party Services”</span>) or
                  may, in some cases, integrate them with our Services. When you
                  click on a link to or access or use a Third-Party Service,
                  Oval Finance will not warn you that you have left Oval Finance
                  Properties and are subject to the terms and conditions
                  (including privacy policies) of another website or
                  destination. Such Third-Party Services are not under the
                  control of Oval Finance. Oval Finance is not responsible for
                  any Third-Party Services. Oval Finance provides these
                  Third-Party Services only as a convenience and does not
                  review, approve, monitor, endorse, warrant, or make any
                  representations with respect to Third-Party Services, or any
                  product or service provided in connection therewith. You use
                  all links in Third-Party Services at your own risk. When you
                  leave our Website, this Agreement and our policies no longer
                  govern. You should review applicable terms and policies,
                  including privacy and data gathering practices, of any
                  Third-Party Services, and make whatever investigation you feel
                  necessary or appropriate before proceeding with any
                  transaction with any third party.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Exchange Services</TermsHeaderComponent>
                <p>
                  In order to use our Services, you may need to link your bank
                  account to your Custodial Account in order to send and receive
                  fiat currency. When funding your Custodial Account, you
                  authorize Prime Trust, Oval Finance, or any of such party’s
                  respective payment processors to debit your bank account and
                  credit your Custodial Account according to your instructions
                  as provided by you via our Services (and to the extent funds
                  are not received, returned, reversed, or charged back from
                  your bank account but are made available in your bank cccount,
                  you authorize Prime Trust, Oval Finance, or any of such
                  party’s respective payment processors to debit your Custodial
                  Account for such funds made available to you). When
                  transferring fiat or digital currency out of your Custodial
                  Account, you also authorize Prime Trust to debit your
                  Custodial Account and credit your bank account according to
                  your instructions as provided by you via our Services.
                  Transfer times are not guaranteed in any way.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Outbound Transfers</TermsHeaderComponent>
                <p>
                  Sending any fiat currency from your Custodial Account to your
                  financial institution (“Outbound Transfers”) will be at your
                  own risk and peril. These Outbound Services are executed by
                  Prime Trust via the Service at your instructions. You should
                  verify all transaction information prior to submitting
                  instructions to us.
                </p>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>
              ELECTRONIC COMMUNICATION AND E-SIGN AGREEMENT
            </TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                <TermsHeaderComponent>
                  Electronic Communications
                </TermsHeaderComponent>
                <p>
                  The communications between you and Oval Finance may take place
                  via electronic means, whether you visit Oval Finance
                  Properties or send Oval Finance e-mails, or whether Oval
                  Finance posts notices on Oval Finance Properties or
                  communicates with you via e-mail. For contractual purposes,
                  you (a) consent to receive communications from Oval Finance in
                  an electronic form; and (b) agree that all terms and
                  conditions, agreements, notices, disclosures, and other
                  communications that Oval Finance provides to you
                  electronically satisfy any legal requirement that such
                  communications would satisfy if it were to be in writing.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Electronic Transactions
                </TermsHeaderComponent>
                <p>
                  With your consent, Oval Finance will send that information to
                  you electronically, instead, in accordance with the federal
                  Electronic Signatures in Global and National Commerce Act (
                  <span className="bold-21">“E-Sign Act”</span>). You consent to
                  receive communications in electronic form for any Oval Finance
                  products, services, and accounts that you apply for or obtain,
                  either now or in the future. You also consent to the use of
                  electronic signatures in your relationship with Oval Finance
                  and to enter into electronic transactions with Oval Finance.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Electronic Delivery</TermsHeaderComponent>
                <p>
                  By accepting the terms of this section, you agree that Oval
                  Finance may provide the documents and all disclosures to you
                  electronically in the manner specified in any other agreement
                  Oval Finance has with you. The documents and disclosures may
                  be provided by us to you directly or by a third-party service
                  provider.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Withdrawal of Consent
                </TermsHeaderComponent>
                <p>
                  You have the right to withdraw your consent and agreement to
                  receive electronic communications. If you withdraw your
                  consent, we may terminate your account with us and related
                  services. You may withdraw your consent by contacting us at{" "}
                  <a className="bold-21" href="mailto:support@ovalfi.com">
                    support@ovalfi.com
                  </a>
                  .
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Hardware and Software Requirements
                </TermsHeaderComponent>
                <p>
                  In order to receive access and retain the documents and
                  disclosures from Oval Finance electronically, you will need
                  the following computer software and hardware (such
                  requirements may vary and are subject to change): <br />
                  &nbsp; &#8226; A mobile device running the latest Apple iOS or
                  the latest Android.
                  <br />
                  &nbsp; &#8226; A computer capable of running the latest
                  version of a modern web browser like, Microsoft Edge, Safari,
                  Chrome, Firefox, or Brave.
                  <br />
                  &nbsp; &#8226; The ability to view and retain Portable
                  Document Format (“PDF”) files.
                  <br />
                  &nbsp; &#8226; Sufficient storage space to save communications
                  or documents sent by Oval Finance and the capability to print
                  the communications or documents from your computer or mobile
                  device.
                  <br />
                  &nbsp; &#8226; A current version of a program that accurately
                  reads and PDF files, such as the latest version of Adobe
                  Acrobat Reader.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Changes to Your Contact Information
                </TermsHeaderComponent>
                <p>
                  You agree to keep your personal information provided to Oval
                  Finance current at all times. You agree to promptly notify
                  Oval Finance if there is a change in Your Content, or in other
                  information Oval Finance may need to contact you.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Consent and Agreement
                </TermsHeaderComponent>
                <p>
                  By clicking the “Submit” button, you consent to having all
                  documents and disclosures provided or made available to you in
                  electronic form, to these Terms of Use and to doing business
                  with us electronically. You also consent and agree to the use
                  of electronic records and electronic signatures in connection
                  with transactions with us instead of written documents and
                  handwritten signatures. Moreover, BY CLICKING “SUBMIT” you
                  agree to these Terms of Use and choose to conduct this
                  transaction electronically, receive the documents and
                  disclosures electronically and be legally bound by use of your
                  electronic signature in connection with the Services.
                </p>
                <p className="bold-21">
                  You are not permitted to access or use the Services without
                  providing the acceptance in this Section.
                </p>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>

          <TermsContainer>
            <TermsHeaderComponent>GENERAL PROVISIONS</TermsHeaderComponent>
            <TermsListBodyComponent>
              <li>
                <TermsHeaderComponent>Release</TermsHeaderComponent>
                <p>
                  To the fullest extent provided by law, you hereby release Oval
                  Finance Parties and their successors from claims, demands, any
                  and all losses, damages, rights, and actions of any kind,
                  including personal injuries, death, and property damage, that
                  is either directly or indirectly related to or arises from
                  your use of Oval Finance Properties, including but not limited
                  to, any interactions with or conduct of other users or
                  third-party websites of any kind arising in connection with or
                  as a result of the Agreement or your use of Oval Finance
                  Properties.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Assignment</TermsHeaderComponent>
                <p>
                  The Agreement, and your rights and obligations hereunder, may
                  not be assigned, subcontracted, delegated or otherwise
                  transferred by you without Oval Finance’s prior written
                  consent, and any attempted assignment, subcontract,
                  delegation, or transfer in violation of the foregoing will be
                  null and void.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Force Majeure</TermsHeaderComponent>
                <p>
                  Oval Finance shall not be liable for any delay or failure to
                  perform resulting from causes outside its reasonable control,
                  including, but not limited to, acts of God, war, terrorism,
                  riots, embargos, acts of civil or military authorities, fire,
                  floods, accidents, strikes or shortages of transportation
                  facilities, fuel, energy, labor or materials.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>
                  Questions, Complaints, Claims
                </TermsHeaderComponent>
                <p>
                  If you have any questions, complaints or claims with respect
                  to Oval Finance Properties, please contact us at:{" "}
                  <a className="bold-21" href="mailto:support@ovalfi.com">
                    support@ovalfi.com
                  </a>
                  . We will do our best to address your concerns. If you feel
                  that your concerns have been addressed incompletely, we invite
                  you to let us know for further investigation.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Vulnerabilities</TermsHeaderComponent>
                <p>
                  If you would like to report a vulnerability or have a security
                  concern regarding our Platform, Website, or other aspects of
                  our Services, please e-mail{" "}
                  <a className="bold-21" href="mailto:support@ovalfi.com">
                    support@ovalfi.com
                  </a>
                  .
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Exclusive Venue</TermsHeaderComponent>
                <p>
                  To the extent the parties are permitted under this Agreement
                  to initiate litigation in a court, both you and Oval Finance
                  agree that all claims and disputes arising out of or relating
                  to the Agreement will be litigated exclusively in the courts
                  located in the State of Texas, city of Austin.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Governing Law</TermsHeaderComponent>
                <p>
                  THE TERMS AND ANY ACTION RELATED THERETO WILL BE GOVERNED AND
                  INTERPRETED BY AND UNDER THE LAWS OF THE STATE OF TEXAS,
                  CONSISTENT WITH THE FEDERAL LAWS OF THE UNITED STATES OF
                  AMERICA.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Notice</TermsHeaderComponent>
                <p>
                  Where Oval Finance requires that you provide an e-mail
                  address, you are responsible for providing Oval Finance with
                  your most current e-mail address. In the event that the last
                  e-mail address you provided to Oval Finance is not valid, or
                  for any reason is not capable of delivering to you any notices
                  required/permitted by the Agreement, Oval Finance’s dispatch
                  of the e-mail containing such notice will nonetheless
                  constitute effective notice. You may give notice to Oval
                  Finance at the following address:
                </p>
                <p>
                  Oval Labs, Inc. <br />
                  8 The Green, Ste A, <br />
                  Dover, DE 19901
                </p>
                <p>
                  Email:{" "}
                  <a className="bold-21" href="mailto:support@ovalfi.com">
                    support@ovalfi.com
                  </a>
                </p>
                <p>
                  Such notice shall be deemed given when received by Oval
                  Finance by e-mail, or by letter delivered by nationally
                  recognized overnight delivery service or first-class postage
                  prepaid mail at the above address.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Waiver</TermsHeaderComponent>
                <p>
                  Any waiver or failure to enforce any provision of the
                  Agreement on one occasion will not be deemed a waiver of any
                  other provision or of such provision on any other occasion.
                </p>
              </li>

              <li className="more-space">
                <TermsHeaderComponent>Severability</TermsHeaderComponent>
                <p>
                  If any portion of this Agreement is held invalid or
                  unenforceable, that portion shall be construed in a manner to
                  reflect, as nearly as possible, the original intention of the
                  parties, and the remaining portions shall remain in full force
                  and effect.
                </p>
              </li>

              <li>
                <TermsHeaderComponent>Entire Agreement</TermsHeaderComponent>
                <p>
                  The Agreement is the final, complete and exclusive agreement
                  of the parties with respect to the subject matter hereof and
                  supersedes and merges all prior discussions between the
                  parties with respect to such subject matter.
                </p>
              </li>
            </TermsListBodyComponent>
          </TermsContainer>
        </ul>
      </div>
    </div>
  );
};

export default Terms;
