import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  TermsHeaderComponent,
  TermsTextBodyComponent
} from "../../components/TermsComponent/TermsComponent";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="terms-page">
      <div className="terms-wrapper desktop-max-width-smaller">
        <div className="terms-intro">
          <p className="extra-21">OVAL FINANCE PRIVACY POLICY</p>
          <p>Effective Date: February 7, 2022</p>
        </div>

        <TermsTextBodyComponent>
          <p>
            We at Oval Finance know you care about how your personal information
            is used and shared, and we take your privacy seriously. Please read
            the following to learn more about our Privacy Policy.
          </p>

          <p className="bold-21">
            By using or accessing the Services in any manner, you acknowledge
            that you accept the practices and policies outlined in this Privacy
            Policy, and you hereby consent that we will collect, use, and share
            your information in the following ways.
          </p>

          <p>
            Remember that your use of Oval Finance’s Services is at all times
            subject to our{" "}
            <Link className="bold-21" to="/terms-of-service">
              Terms of Use
            </Link>
            , which incorporates this Privacy Policy. Any terms we use in this
            Privacy Policy without defining them have the definitions given to
            them in our Terms of Use.
          </p>

          <br />
          <TermsHeaderComponent>
            <p className="bold-21">What does this Privacy Policy cover?</p>
          </TermsHeaderComponent>
          <p>
            This Privacy Policy covers our treatment of personally identifiable
            information (<span className="b21 i21">“Personal Information”</span>
            ) that we gather when you are accessing or using our Services, but
            not the practices of companies we do not own or control, or people
            that we do not manage. We may gather various types of Personal
            Information from our users, as explained in more detail below, and
            we use this Personal Information internally in connection with our
            Services, including to personalize, provide, and improve our
            services, to fulfill your requests for certain products and
            services, and to analyze how you use the Services. In certain cases,
            we may also share some Personal Information with third parties, but
            only as described below.
          </p>

          <p>
            If you are under the age of majority in your jurisdiction of
            residence, you may use the Services only with the consent of or
            under the supervision of your parent or legal guardian. The Services
            are not intended for use by children under 13 years of age. If we
            learn that we have collected personal information through the
            Services from a child under 13 without the consent of the child’s
            parent or guardian as required by law, we will delete it.
          </p>

          <br />
          <TermsHeaderComponent>
            <p className="b21">
              Will Oval Finance ever change this Privacy Policy?
            </p>
          </TermsHeaderComponent>

          <p>
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you
            by updating the date of this Privacy Policy and posting it on the
            Services. If required by law, we will also provide notification of
            changes in another way that we believe is reasonably likely to reach
            you, such as via e-mail or another manner through the Services. Any
            modifications to this Privacy Policy will be effective upon our
            posting the modified version (or as otherwise indicated at the time
            of posting). In all cases, your use of the Services after the
            effective date of any modified Privacy Policy indicates your
            acceptance of the modified Privacy Policy.
          </p>

          <br />
          <TermsHeaderComponent>
            <p className="b21">What Information does Oval Finance Collect? </p>
          </TermsHeaderComponent>

          <p>
            <span className="b21">Information You Provide to Us:</span> We
            receive and store any information you knowingly provide to us. For
            example, this includes information you provide when you register for
            a Custodial Account such as information regarding your transactional
            history on the digital currency network, information you provide
            each time you wish to make a transfer of digital currency through
            the Services, or usage information, such as information about how
            you use the Services and interact with us. It also includes (i)
            contact information you elect to provide to us such as your e-mail
            address, (ii) feedback and correspondence, such as information you
            provide in your responses to surveys, to report a problem with our
            Services, or otherwise correspond with us, and (iii) marketing
            information, such as your preferences for receiving marketing
            communications and details about how you engage with them. Other
            than the information required to associate your Custodial Account
            and/or Waller with the Platform or Website, you are not required to
            provide any other Personal Information to use the basic features of
            our Services, but you may be required to provide additional Personal
            Information to access additional features or offerings.
          </p>

          <p>
            <span className="b21">
              Information Collected from Other Sources:
            </span>{" "}
            We may obtain information from other sources, such as third parties
            in connection with your use of our Services. We may combine this
            with information previously collected. In these cases, our Privacy
            Policy governs the handling of the combined Personal Information.
          </p>

          <p>
            <span className="b21"> Information Collected Automatically:</span>
            Whenever you interact with certain parts of our Services such as our
            landing page located at [www.ovalfi.com] or any of our webpages
            (collectively with the Website, the “Site”), we automatically
            receive and record information on our server logs from your browser
            or device, which may include your IP address, geolocation data,
            device identification, “cookie” information, the type of browser
            and/or device you’re using to access our Services, and the page or
            feature you requested. We typically collect this information through
            a variety of tracking technologies, including cookies, embedded
            scripts, web beacons, file information and similar technology
            (collectively,
            <span className="b21 i21"> “tracking technologies”</span>).{" "}
            <span className="b21 i21"> “Cookies”</span> are identifiers we
            transfer to your browser or device that allow us to recognize your
            browser or device and tell us how and when pages and features in our
            Site are visited and by how many people. You may be able to change
            the preferences on your browser or device to prevent or limit your
            device’s acceptance of cookies, but this may prevent you from taking
            advantage of some of our features. If you click on a link to a
            third-party website or service, such third party may also transmit
            cookies to you. This Privacy Policy does not cover the use of
            cookies by any third parties, and we aren’t responsible for their
            privacy policies and practices. Please be aware that cookies placed
            by third parties may continue to track your activities online even
            after you have left our Services.
          </p>

          <p>
            We may use this data to customize content for you that we think you
            might like, based on your usage patterns. We may also use it to
            improve the Services. For example, this data can tell us how often
            users use a particular feature of the Services, and we can use that
            knowledge to make the Services interesting to as many users as
            possible.
          </p>

          <p>
            We do not automatically collect Personal Information or other
            nonpublic information in connection with transactions on digital
            currency networks you conduct using our Services through the
            Platform or Website, and we do not use any automatic tracking
            technologies in these parts of our Services unless you explicitly
            agree to the use of these tracking technologies.
          </p>

          <p>
            <span className="b21">Do Not Track Policy:</span> Your browser may
            offer you a “Do Not Track” option, which allows you to signal to
            operators of websites and web applications and services (including
            behavioral advertising services) that you do not wish such operators
            to track certain of your online activities over time and across
            different websites. Do Not Track signals are set on a
            browser-by-browser basis, so you must set them on every browser you
            use if you do not wish to be tracked.
          </p>

          <p>
            We do not track our users over time and across third party websites
            to provide targeted advertising and therefore we do not respond to
            Do Not Track requests. However, some third party sites do keep track
            of your browsing activities when they serve you content, which
            enables them to tailor what they present to you, and these third
            parties may not honor Do Not Track requests. Remember that this is
            just our Do Not Track policy, and we cannot and do not make any
            promises about how third parties react when you set this signal on
            your browser, including any third party sites we may link to. You
            should always review the privacy policy and specifically, the Do Not
            Track policy, of a third party website to understand how they will
            respond to Do Not Track requests.
          </p>

          <p>
            <span className="b21">Information we will never collect:</span> We
            will never ask you to share your password to your Custodial Account
            or other security information that could be used to access your
            Custodial Account without your explicit consent and action.
          </p>

          <br />
          <TermsHeaderComponent>
            <p>
              How does Oval Finance Use the Personal Information it Receives?
            </p>
          </TermsHeaderComponent>

          <p>
            As a general policy, we aim to protect your privacy and Personal
            Information by collecting, using or sharing as little Personal
            Information as possible in order to provide our Services unless you
            explicitly agree in writing to additional collection, use or
            sharing.
          </p>

          <p>
            To the extent we do use Personal Information, we use such
            information to provide and improve the Services, optimize our
            technology, refine the experience of our users, and innovate ways to
            forward our mission.
          </p>

          <p>Namely, we use the information we collect to:</p>
          <p className="p21">
            &#8226; Administer the Services, enable you to use its features, and
            improve your overall user experience;
            <br />
            &#8226; Send information including confirmations, technical notices,
            updates, security alerts, and support and administrative messages;{" "}
            <br />
            &#8226; Respond to comments and questions and provide customer
            service;
            <br />
            &#8226; Analyze how users use the Services to help us optimize the
            Services and know if there are problems with the Services; <br />
            &#8226; Protect, investigate, and deter against fraudulent,
            unauthorized, or illegal activity; <br />
            &#8226; With your consent, to link or combine user information with
            other Personal Information; <br />
            &#8226; To provide and deliver products and services requested by
            customers; and
            <br />
            &#8226; As disclosed below under the section of this Privacy Policy
            entitled “Will Oval Finance Share Any of the Personal Information it
            Receives?” <br />
          </p>

          <br />
          <TermsHeaderComponent>
            <p>
              Will Oval Finance Share Any of the Personal Information it
              Receives?
            </p>
          </TermsHeaderComponent>

          <p>
            We do not share or sell the Personal Information that you provide us
            with other organizations without your express consent, except as
            described in this Privacy Policy. We may share your Personal
            Information with third parties as described below:
          </p>

          <p className="p21">
            &#8226; <span className="b21">Consent:</span> We may share Personal
            Information with your explicit consent.
            <br />
            &#8226; <span className="b21">Affiliated Businesses:</span> In
            certain situations, businesses or third-party websites we are
            affiliated with, including but not limited to Prime Trust, may sell
            or provide products or services to you through or in connection with
            the Services (either alone or jointly with us). You can recognize
            when an affiliated business is associated with such a transaction or
            service, and we will share your Personal Information with that
            affiliated business only to the extent that it is related to such
            transaction or service. We have no control over the policies and
            practices of third-party websites or businesses as to privacy or
            anything else, so if you choose to take part in any transaction or
            service relating to an affiliated website or business, please review
            all of these businesses’ or websites’ policies.
            <br />
            &#8226; <span className="b21">Our Agents:</span> We employ other
            companies and people to perform tasks on our behalf and need to
            share your information with them to provide products or services to
            you or to us. Unless we tell you differently, our agents do not have
            any right to use the Personal Information we share with them beyond
            what is necessary to assist us.
            <br />
            &#8226; <span className="b21">Business Transfers:</span> We may
            choose to buy or sell assets and may share and/or transfer customer
            information in connection with the evaluation of and entry into such
            transactions. Also, if we (or our assets) are acquired, or if we go
            out of business, enter bankruptcy, or go through some other change
            of control, Personal Information could be one of the assets
            transferred to or acquired by a third party.
            <br />
            &#8226; <span className="b21">Third Party Services:</span> We may
            use third party services, such as Google Analytics, to grow our
            business, to improve and develop our Services, to monitor and
            analyze use of our Services, to aid our technical administration, to
            increase the functionality and user-friendliness of our Services,
            and to verify that users have the authorization needed for us to
            process their requests. These services may collect and retain some
            information about you, such as the IP address assigned to you on the
            date you use the Services, but not your name or other personally
            identifying information. We may combine the information generated
            through the use of these services with your Personal Information but
            never in a way that will identify you to any other user or third
            party. Although these services may plant a persistent cookie on your
            web browser to identify you as a unique user the next time you use
            the Services, the cookie cannot be used by anyone but Google. Google
            Analytics services’ ability to use and share information about your
            use of the Services is restricted by the Google Analytics Terms of
            Use and the Google Privacy Policy. You may find additional
            information about Google Analytics at{" "}
            <a href="https://google.com/policies/privacy/partners/">
              https://google.com/policies/privacy/partners/
            </a>
            . You can opt out of Google Analytics by visiting
            <a href=" https://tools.google.com/dlpage/gaoptout/">
              {" "}
              https://tools.google.com/dlpage/gaoptout/
            </a>
            .
            <br />
            &#8226;{" "}
            <span className="b21">
              Protection of Oval Finance and Others:
            </span>{" "}
            We reserve the right to access, read, preserve, and disclose any
            information that we believe is necessary to comply with law or court
            order; enforce or apply our Terms of Use and other agreements; or
            protect the rights, property, or safety of Oval Finance, our
            employees, our users, or others.
          </p>

          <p>
            When we share Personal Information, we will not share such Personal
            Information in a manner that can be used to identify you
            individually or in a manner that provides more Personal Information
            than is publicly available (if applicable) unless otherwise
            explicitly agreed by you or instructed by you, and unless we are
            legally required to do so.
          </p>
          <br />
          <TermsHeaderComponent>
            Is Personal Information about me secure?
          </TermsHeaderComponent>

          <p>
            We endeavor to protect the privacy of the Personal Information we
            hold in our records, but unfortunately, we cannot guarantee complete
            security. The safety and security of your Personal Information also
            depend on you. Unauthorized entry or use, hardware or software
            failure, and other factors, may compromise the security of user
            information at any time. Your Custodial Account is protected by your
            password, and we urge you to take steps to keep this and other
            Personal Information safe by not disclosing your security
            credentials or leaving your Custodial Account open in an unsecured
            manner. We further protect your Personal Information from potential
            security breaches by implementing certain technological security
            measures including encryption, firewalls and secure socket layer
            technology. We also seek to protect Personal Information by
            refraining from collecting Personal Information where possible.
            However, these measures do not guarantee that your Personal
            Information will not be accessed, disclosed, altered or destroyed by
            breach of such firewalls and secure server software. By using our
            Services, you acknowledge that you understand and agree to assume
            these risks.
          </p>

          <p>
            We may use any aggregated data derived from or incorporating your
            Personal Information after you update or delete it, but not in a
            manner that would identify you personally.
          </p>

          <br />
          <TermsHeaderComponent>EU RESIDENTS</TermsHeaderComponent>
          <p>
            If you are a resident of the European Economic Area (
            <span className="b21">“EEA”</span>) or Switzerland, you may have
            additional rights under the General Data Protection Regulation (the{" "}
            <span className="b21">“GDPR”</span>) and other applicable laws with
            respect to your Personal Data, as outlined below.
          </p>

          <p>
            For the purpose of this section, we use the terms “Personal Data”
            and “processing” as they are defined in the GDPR, but{" "}
            <span className="b21">“Personal Data”</span> generally means
            information that can be used to individually identify a person, and{" "}
            <span className="b21">“processing”</span> generally covers actions
            that can be performed in connection with data such as collection,
            use, storage and disclosure. Oval Finance will be the controller of
            your Personal Data processed in connection with the Services.
          </p>

          <p>
            If there are any conflicts between this section and any other
            provision of this Privacy Policy, the policy or portion that is more
            protective of Personal Data shall control to the extent of such
            conflict. If you have any questions about this section or whether
            any of the following applies to you, please contact us at{" "}
            <a className="bold-21" href="mailto:hello@ovalfi.com">
              hello@ovalfi.com
            </a>
            .
          </p>

          <br />
          <TermsHeaderComponent>
            <p>What Personal Data Do We Collect from You?</p>
          </TermsHeaderComponent>

          <p>
            We collect Personal Data about you when you provide such information
            directly to us, when third parties such as our business partners or
            service providers provide us with Personal Data about you, or when
            Personal Data about you is automatically collected in connection
            with your use of our Services.
          </p>

          <p className="p21">
            &#8226;{" "}
            <span className="b21">
              Information we collect directly from you:
            </span>{" "}
            We receive Personal Data directly from you when you provide us with
            such Personal Data, including without limitation, Personal
            Information as described above under “Information You Provide to Us”
            and “Information Collected from Other Sources”, and any other
            information you may elect to submit in your communications with us
            while using our Services. You are not required to submit Personal
            Data solely in connection with your use of your Custodial Account
            through our Services, but certain of our other Services may require
            submission of Personal Data to fully exploit those Services.
            <br />
            &#8226;{" "}
            <span className="b21">
              Information we automatically collect when you use our Services:
            </span>{" "}
            Some Personal Data is automatically collected when you use our
            Services, including without limitation, the information described
            above under “Information Collected Automatically.”
          </p>

          <br />
          <TermsHeaderComponent>
            <p>How Do We Use Your Personal Data?</p>
          </TermsHeaderComponent>

          <p>
            We process Personal Data to operate, improve, understand and
            personalize our Services. See “How does Oval Finance Use the
            Personal Information it Receives?” for more information on how we
            use your Personal Data.
          </p>

          <p>
            We will only process your Personal Data if we have a lawful basis
            for doing so. Lawful bases for processing include consent,
            contractual necessity and our “legitimate interests” or the
            legitimate interest of others, as further described below.
          </p>

          <p className="p21">
            &#8226; <span className="b21">Contractual Necessity:</span> We
            process certain Personal Data as a matter of “contractual
            necessity”, meaning that we need to process the data to perform
            under our Terms of Use with you, which enables us to provide you
            with the Services. When we process data due to contractual
            necessity, failure to provide such Personal Data will result in your
            inability to use some or all portions of the Services that require
            such data.
            <br />
            &#8226; <span className="b21">Legitimate Interest:</span> We may
            also process your Personal Data where it is necessary for our
            legitimate interests (or those of a third party) and your interests
            and fundamental rights do not override those interests. We consider
            and balance any potential impacts on you (both positive and
            negative) and your rights before we process your Personal Data for
            our legitimate interests. We do not use your Personal Data for
            activities where our interests are overridden by any adverse impact
            on you (unless we have your consent or are otherwise required or
            permitted to by law). Examples of these legitimate interests include
            operation and improvement of our business, products and Services,
            provision of customer support, protection from fraud or security
            threats, compliance with legal obligations or completion of
            corporate transactions.
            <br />
            &#8226; <span className="b21">Consent:</span> In some cases, we
            process Personal Data based on the consent you expressly grant to us
            at the time we collect such data. When we process Personal Data
            based on your consent, it will be expressly indicated to you at the
            point and time of collection.
            <br />
            &#8226; <span className="b21">Other Processing Grounds:</span> From
            time to time we may also need to process Personal Data to comply
            with a legal obligation, if it is necessary to protect the vital
            interests of you or other data subjects, or if it is necessary for a
            task carried out in the public interest.
          </p>
          <br />
          <TermsHeaderComponent>
            <p>How and With Whom Do We Share Your Data?</p>
          </TermsHeaderComponent>

          <p>
            We share Personal Data with our agents, third party service
            providers who work on our behalf and provide us with services
            related to the purposes described in this Privacy Policy or our
            Terms of Use, potential business partners in connection with
            business transfers described in this Privacy Policy, or for legal
            purposes. Please see “Will Oval Finance Share Any of the Personal
            Information it Receives?” for more information.
          </p>
          <br />
          <TermsHeaderComponent>
            <p>How Long Do We Retain Your Personal Data?</p>
          </TermsHeaderComponent>

          <p>
            We retain Personal Data about you for as long as necessary to
            provide you Services. In some cases, we retain Personal Data for
            longer, if doing so is necessary to comply with our legal
            obligations, resolve disputes or collect fees owed, or is otherwise
            permitted or required by applicable law, rule or regulation.
            Afterwards, we may retain some information in a depersonalized or
            aggregated form but not in a way that would identify you personally.
          </p>
          <br />
          <TermsHeaderComponent>
            What Security Measures Do We Use?
          </TermsHeaderComponent>
          <p>
            We seek to protect Personal Data using appropriate technical and
            organizational measures based on the type of Personal Data and
            applicable processing activity, and, where possible, we refrain from
            collecting Personal Data. Oval Finance regularly audits its system
            for any possible security vulnerabilities to safeguard Personal
            Data. Oval Finance protects the security of your information during
            transmission by using Transport Layer Security (TSL/SSL), which
            encrypts all information that you input.{" "}
          </p>
          <br />
          <TermsHeaderComponent>
            <p>What Rights Do You Have Regarding Your Personal Data?</p>
          </TermsHeaderComponent>

          <p>
            You have certain rights with respect to your Personal Data,
            including those set forth below. For more information about these
            rights, or to submit a request, please email hello@ovalfi.com.
            Please note that in some circumstances, we may not be able to fully
            comply with your request, such as if it is frivolous or extremely
            impractical, if it jeopardizes the rights of others, or if it is not
            required by law, but in those circumstances, we will still respond
            to notify you of such a decision. In some cases, we may also need to
            you to provide us with additional information, which may include
            Personal Data, if necessary, to verify your identity and the nature
            of your request.
          </p>

          <p className="p21">
            &#8226; <span className="b21">Access:</span> You can request more
            information about the Personal Data we hold about you and request a
            copy of such Personal Data. You can also access certain of your
            Personal Data by visiting the Platform or Website.
            <br />
            &#8226; <span className="b21">Rectification:</span> If you believe
            that any Personal Data we are holding about you is incorrect or
            incomplete, you can request that we correct or supplement such data.
            You can correct some of this information directly by editing them on
            the Platform or Website.
            <br />
            &#8226; <span className="b21">Erasure:</span> You can request that
            we erase some or all your Personal Data from our systems, &#8226;{" "}
            <span className="i21">provided</span>
            that this will not erase any Personal Data you have submitted to the
            Ethereum network.
            <br />
            &#8226; <span className="b21">Withdrawal of Consent:</span> If we
            are processing your Personal Data based on your consent (as
            indicated at the time of collection of such data), you have the
            right to withdraw your consent at any time. Please note that if you
            exercise this right, you may have to then provide express consent on
            a case-by-case basis for the use or disclosure of certain of your
            Personal Data, if such use or disclosure is necessary to enable you
            to utilize some or all of our Services.
            <br />
            &#8226; <span className="b21">Portability:</span> You can ask for a
            copy of your Personal Data in a machine-readable format. You can
            also request that we transmit the data to another controller where
            technically feasible.
            <br />
            &#8226; <span className="b21">Objection:</span> You can contact us
            to let us know that you object to the further use or disclosure of
            your Personal Data for certain purposes.
            <br />
            &#8226; <span className="b21">Restriction of Processing:</span> You
            can ask us to restrict further processing of your Personal Data.
            <br />
            &#8226; <span className="b21">Right to File Complaint:</span> You
            have the right to lodge a complaint about Oval Finance’s practices
            with respect to your Personal Data with the supervisory authority of
            your country or European Union Member State.
          </p>
          <br />
          <TermsHeaderComponent>
            <p>Transfers of Personal Data</p>
          </TermsHeaderComponent>

          <p>
            Certain of the Services are hosted and operated in part in the
            United States of America through Oval Finance and its service
            providers, and if you do not reside in the United States of America,
            laws in the United States of America may differ from the laws where
            you reside. By using the Services and providing your information,
            you acknowledge that any Personal Data about you, regardless of
            whether provided by you or obtained from a third party, may be
            provided to Oval Finance in the United States of America and may be
            hosted on US servers. You hereby consent to and authorize Oval
            Finance to transfer, store and process your Personal Data to and in
            the United States of America, and possibly other countries. We will
            take all steps reasonably necessary to ensure that your information
            is treated securely and in accordance with this Privacy Policy. One
            such step we may take to ensure the security of your Personal Data
            in the event that a transfer may not be subject to the same
            protection in the EEA or Switzerland, is to enter into specific
            contractual clauses approved by the European Commission which ensure
            your personal information is given the same protection it has in
            Europe.
          </p>
          <br />
          <TermsHeaderComponent>
            <p>What If You Have Questions Regarding Your Personal Data?</p>
          </TermsHeaderComponent>

          <p>
            If you have any questions about this section or our data practices
            generally, please contact us using the following information:
          </p>

          <p className="p21">
            &#8226; <span className="b21">Designated Representative Name:</span>{" "}
            Chinedu Okpala
            <br />
            &#8226; <span className="b21">Physical address: </span>8 The Green,
            Ste A, Dover, DE 19901
            <br />
            &#8226; <span className="b21">
              e-mail address for contact:
            </span>{" "}
            <a className="bold-21" href="mailto:hello@ovalfi.com">
              hello@ovalfi.com
            </a>
          </p>
          <br />

          <TermsHeaderComponent>
            <p>What if I have questions about this policy?</p>
          </TermsHeaderComponent>

          <p>
            If you have any questions or concerns regarding our privacy
            policies, please send us a detailed message to{" "}
            <a className="bold-21" href="mailto:hello@ovalfi.com">
              hello@ovalfi.com
            </a>{" "}
            and we will try to resolve your concerns.
          </p>

          <TermsHeaderComponent>
            <p>PRIVACY POLICY ADDENDUM FOR CALIFORNIA RESIDENTS</p>
          </TermsHeaderComponent>

          <p>
            This Privacy Policy Addendum supplements the Privacy Policy and
            describes additional rights of residents of the State of California.
          </p>

          <p>
            Persons with disabilities may obtain this notice in alternative
            format upon request by contacting us at{" "}
            <a className="bold-21" href="mailto:hello@ovalfi.com">
              hello@ovalfi.com
            </a>
          </p>

          <TermsHeaderComponent>
            <p>Your California Privacy Rights</p>
          </TermsHeaderComponent>

          <p>
            California residents are entitled once a year, free of charge, to
            request and obtain certain information regarding our disclosure, if
            any, of certain categories of personal information to third parties
            for their direct marketing purposes in the preceding calendar year.
            We do not share personal information with third parties for their
            own direct marketing purposes.{" "}
          </p>

          <TermsHeaderComponent>
            <p>California Consumer Privacy Act</p>
          </TermsHeaderComponent>

          <p>
            The <span className="i21">California Consumer Privacy Act</span> (
            <span className="b21">“CCPA”</span>) provides California residents
            with rights to receive certain disclosures regarding the collection,
            use, and sharing of “Personal Information” (as defined in this
            section), as well as rights to know/access, delete, and limit
            sharing of Personal Information.
          </p>

          <p>
            The CCPA defines <span className="b21">“Personal Information”</span>{" "}
            to mean{" "}
            <span className="i21">
              “information that identifies, relates to, describes, is reasonably
              capable of being associated with, or could reasonably be linked,
              directly or indirectly, with a particular consumer or household.”
            </span>{" "}
            Certain information we collect may be exempt from the CCPA because
            it is considered public information (i.e., it is made available by a
            government entity) or covered by a specific federal privacy law,
            such as{" "}
            <span className="i21">
              the Gramm–Leach–Bliley Act, the Health Insurance Portability and
              Accountability Act, or the Fair Credit Reporting Act
            </span>
            . Your rights are described below:
          </p>

          <TermsHeaderComponent>
            <p>
              Right to Notice at Collection Regarding the Categories of Personal
              Information Collected
            </p>
          </TermsHeaderComponent>

          <p>
            You have the right to receive notice of the categories of Personal
            Information we collect, and the purposes for which those categories
            of Personal Information will be used. This notice should be provided
            at or before the time of collection. The categories we use to
            describe the information are those enumerated in the CCPA.
          </p>

          <p className="p21">
            &#8226; <span className="b21">Identifiers.</span> We collect your
            name, phone number, contact address and e-mail address when you
            engage with our website. We use this information to manage and
            provide the Services that you request, respond to your requests, and
            to communicate with you about the Services. We collect your social
            media handle when you interact with our Services through social
            media. We use this information to improve the user experience and
            our Service.
            <br />
            &#8226;{" "}
            <span className="b21">
              Personal information categories listed in the California Customer
              Records statute (Cal. Civ. Code § 1798.80(e)).
            </span>{" "}
            In addition to the identifiers in the above section, we collect your
            credit card number to provide you with requested Services.
            <br />
            &#8226;{" "}
            <span className="b21">
              Internet or other similar network activity.
            </span>{" "}
            We automatically collect information about your browsing history and
            your interaction with the content of our Services to measure
            activity, determine the effectiveness of our Services, and improve
            them.
            <br />
            &#8226; <span className="b21">Sensory Data.</span> We collect your
            audio or voice recordings to provide you with Services.
          </p>

          <p>
            We may use any of the categories of information listed above for
            other business or operational purposes compatible with the context
            in which the Personal Information was collected.
          </p>

          <p>
            We may share any of the above-listed information with service
            providers, as described above. Service providers are restricted from
            using Personal Information for any purpose that is not related to
            our engagement. The types of service providers with whom we share
            information and the services they provide are described in the
            Privacy Policy. We have not sold any personal information.
          </p>
          <br />

          <TermsHeaderComponent>
            <p>Right to Know/Access Information</p>
          </TermsHeaderComponent>

          <p>
            You have the right to request access to Personal Information
            collected about you over the past 12 months and information
            regarding the source of that information, the purposes for which we
            collect it, and the third parties and service providers with whom we
            share it. You may submit such a request as described below. To
            protect our users’ Personal Information, we are required to verify
            your identity before we can act on your request.
          </p>
          <br />

          <TermsHeaderComponent>
            <p>Right to Request Deletion of Information </p>
          </TermsHeaderComponent>

          <p>
            You have the right to request in certain circumstances that we
            delete any Personal Information that we have collected directly from
            you. You may submit such a request as described below. To protect
            our users’ Personal Information, we are required to verify your
            identity before we can act on your request. We may have a reason
            under the law why we do not have to comply with your request, or why
            we may comply with it in a more limited way than you anticipated. If
            we do, we will explain that to you in our response.
          </p>
          <br />

          <TermsHeaderComponent>
            <p>How to Submit a Request </p>
          </TermsHeaderComponent>

          <p>
            You may submit a request to exercise your rights to know/access or
            delete your Personal Information by sending us an e-mail at{" "}
            <a className="bold-21" href="mailto:hello@ovalfi.com">
              hello@ovalfi.com
            </a>
            .
          </p>

          <p>
            Only you or your authorized agent may make a verifiable consumer
            request related to your personal information. If you use an
            authorized agent to submit a request on your behalf, we may require
            that you (1) provide the authorized agent written permission to do
            so, and (2) provide a copy of the authorization or provide a copy of
            a power of attorney that complies with California Probate Code
            sections 4000 to 4465 so that we can verify the identity of the
            authorized agent.
          </p>

          <p>
            In verifying requests, we employ reasonable measures to detect
            fraudulent requests and prevent unauthorized access to your Personal
            Information. To meet our obligations, we are required to verify your
            identity, and the identity of your authorized agent, if the request
            is submitted via an agent, by associating the information provided
            in your request to Personal Information previously collected by us.{" "}
          </p>

          <p>
            If we suspect fraudulent or malicious activity on or from the
            password-protected account, we may decline a request or request that
            you provide further verifying information.
          </p>

          <p>
            You may only make a verifiable consumer request twice within a
            12-month period. Making a verifiable consumer request does not
            require you to create an account with us. We will only use personal
            information provided in a verifiable consumer request to verify your
            identity or authority to make the request.
          </p>
          <br />

          <TermsHeaderComponent>
            <p>
              Right to Opt Out of Sale of Personal Information to Third Parties
            </p>
          </TermsHeaderComponent>

          <p>
            You have the right to opt out of any sale of your Personal
            Information by us to third parties. We do not sell information to
            third parties.
          </p>

          <br />

          <TermsHeaderComponent>
            <p>
              Right to Information Regarding Participation in Data Sharing for
              Financial Incentives
            </p>
          </TermsHeaderComponent>

          <p>
            You have the right to be free from discrimination based on your
            exercise of your CCPA rights. We do not discriminate against anyone
            who chooses to exercise their CCPA rights.
          </p>
        </TermsTextBodyComponent>
      </div>
    </div>
  );
};

export default Privacy;
