import { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Landing from "./pages/Landing";
import GetSupport from "./pages/GetSupport";
import ContactUs from "./pages/Contactus";
import ScheduleDemo from "./pages/ScheduleDemo/ScheduleDemo";
import PrivacyConsentModal from "./components/base/PrivacyConsentModal";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy/Privacy";
// import Treasury from "./pages/Treasury";
// import Terminal from "./pages/Terminal";
// import Channel from "./pages/Channel";
// import About from "./pages/About";
import "animate.css/animate.min.css";
import "./App.scss";
import { SHOWN_PRIVACY_CONSENT_MODAL } from "./utils/constants";
import { geolocationDataService } from "./services/geolocation";
function App() {
  const location = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [shownPrivacyConsentModal, setShownPrivacyConsentModal] =
    useState(true);
  const [isUserInNigeria, setIsUserInNigeria] = useState(false);
  const [geolocationStatus, setGeolocationStatus] = useState("");
  const [millisecondsToElapse] = useState(2000);
  const cookiePayload = {
    cookieName: "Oval Finance Cookie",
    daysBeforeExpiration: 3,
    cookieValue: {
      privacyPolicy: false,
      cookiePolicy: false
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getUserLocationViaBrowser();
    }, millisecondsToElapse);
  }, [millisecondsToElapse]);

  // functions
  const getUserData = async () => {
    // destructure cookie-policy value
    const {
      cookieValue: { cookiePolicy }
    } = cookiePayload;
    if (!cookiePolicy) return;
    const responseData = await geolocationDataService();
    const { ip } = responseData;
    return ip;
  };
  const closePrivacyConsentModal = () => {
    setShownPrivacyConsentModal(true);
    localStorage.setItem(
      SHOWN_PRIVACY_CONSENT_MODAL.label,
      SHOWN_PRIVACY_CONSENT_MODAL.value
    );
  };
  const setCookie = (payload) => {
    const { cookieName, cookieValue, daysBeforeExpiration } = payload;
    const d = new Date();
    d.setTime(d.getTime() + daysBeforeExpiration * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();

    const jsonString = JSON.stringify(cookieValue);

    document.cookie =
      cookieName +
      "=" +
      encodeURIComponent(jsonString) +
      ";" +
      expires +
      ";path=/";
  };
  const cookieActionHandler = (payload) => {
    cookiePayload.cookieValue = payload;
    setCookie(cookiePayload);
    // todo:
    // get user ip address and other geo-location data
    getUserData();
    // send to backend
  };
  const getCookie = (cookieName) => {
    const name = cookieName + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        const jsonString = decodeURIComponent(
          c.substring(name.length, c.length)
        );
        return JSON.parse(jsonString);
      }
    }
    return null;
  };
  const performCookieAction = () => {
    if (getCookie(cookiePayload.cookieName)) {
      setShownPrivacyConsentModal(true);
      getUserData();
    } else {
      setShownPrivacyConsentModal(false);
    }
  };
  const getUserLocationViaBrowser = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setIsUserInNigeria(isLocationInNigeria(latitude, longitude));
          setGeolocationStatus("successful");
          performCookieAction();
        },
        (error) => {
          // error getting geolocation
          setGeolocationStatus("error");
          performCookieAction();
          return error.message;
        }
      );
    } else {
      // browser doesn't support
      setGeolocationStatus("unsupported");
      performCookieAction();
    }
  };
  const isLocationInNigeria = (latitude, longitude) => {
    const nigeriaBounds = {
      minLatitude: 4.2735,
      maxLatitude: 13.8856,
      minLongitude: 3.1744,
      maxLongitude: 14.677
    };
    return (
      latitude >= nigeriaBounds.minLatitude &&
      latitude <= nigeriaBounds.maxLatitude &&
      longitude >= nigeriaBounds.minLongitude &&
      longitude <= nigeriaBounds.maxLongitude
    );
  };

  const isLightNav = (route) => {
    return ["/schedule-demo", "/terms-of-service", "/privacy-policy"].includes(
      route
    );
  };

  return (
    <div className={`App ${showMobileMenu ? "no-overflow" : ""}`}>
      <Navbar
        theme={isLightNav(location.pathname) ? "light" : ""}
        {...{ showMobileMenu, setShowMobileMenu }}
      />

      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/contact-us" element={<GetSupport />} />
        <Route exact path="/contact-form" element={<ContactUs />} />
        <Route exact path="/schedule-demo" element={<ScheduleDemo />} />
        <Route exact path="/terms-of-service" element={<Terms />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />
        {/* <Route exact path="/treasury" element={<Treasury />} />
        <Route exact path="/terminal" element={<Terminal />} />
        <Route exact path="/channel" element={<Channel />} />
        <Route exact path="/about-us" element={<About />} />  */}
      </Routes>
      {!shownPrivacyConsentModal && (
        <PrivacyConsentModal
          closePrivacyConsentModal={closePrivacyConsentModal}
          cookieAction={cookieActionHandler}
          nigerianUser={isUserInNigeria}
          geolocationStatus={geolocationStatus}
        />
      )}
    </div>
  );
}

export default App;
