import React from "react";
import "./../../pages/Terms/terms.scss";

export const TermsHeaderComponent = (props) => (
  <h2 className="terms_head" {...props}>
    {props.children}
  </h2>
);

export const TermsTextBodyComponent = (props) => (
  <div className="terms_body" {...props}>
    {props.children}
  </div>
);

export const TermsListBodyComponent = (props) => (
  <ul className="terms_body" {...props}>
    {props.children}
  </ul>
);

export const TermsContainer = (props) => (
  <li className="terms_container">{props.children}</li>
);
